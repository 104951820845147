import React from "react";
import { NextSeo } from "next-seo";

export function HomeSeo() {
    return (
        <NextSeo
            title="Full-time freelancer as a Web Developer and UI/UX Designer"
            description="I'm a full-time freelancer as a web Developer And UI/UX Designer, Worked on various types of projects and I'm confident you'll enjoy working with me."
            canonical="https://www.zakariabenali.me/"
            openGraph={{
                url: "https://www.zakariabenali.me/",
                title: "Full-time freelancer as a Web Developer and UI/UX Designer",
                description:
                    "I'm a full-time freelancer as a web Developer And UI/UX Designer, Worked on various types of projects and I'm confident you'll enjoy working with me.",
                images: [
                    {
                        url: `${process.env.NEXT_PUBLIC_CDN}/images/seo.png`,
                        alt: "Og Image Alt"
                    }
                ],
                site_name: "Zakariabenali",
                type: "website"
            }}
            twitter={{
                handle: "@handle",
                site: "@site",
                cardType: "summary_large_image"
            }}
        />
    );
}
